#header {
	border-bottom: 1px solid $greyBorder;
	@include clearfix();
	text-align: right;
	font-size: 0;
	position: relative;
	z-index: 99;
	height: 110px;

	@include breakpoint(medium down) {
		height: 70px;
	}

	#logo {
		float: left;
		display: block;
		line-height: 109px;
		@include transition(0s);

		img {
			width: 110px;
			height: auto;
			vertical-align: middle;

			&.white {
				display: none;
			}
		}

		@include breakpoint(medium down) {
			line-height: 69px;

			img {
				width: 50px;
			}
		}
	}

	nav {
		@include clearfix();

		li {
			display: inline-block;
			vertical-align: middle;

			&:not(:last-child) {
				margin-right: 50px;

				@include breakpoint(large only) {
					margin-right: 26px;
				}
			}

			a {
				display: block;
				font-size: 14px;
				text-transform: uppercase;
				color: $black;
				font-weight: 600;
				line-height: 109px;
				position: relative;
				letter-spacing: 1px;
				@include transitionType(color, 0.3s);

				@include breakpoint(large only) {
					font-size: 13px;
				}

				&:hover {
					color: $blue;
				}

				&.active:after {
					content: "";
					position: absolute;
					bottom: -2px;
					left: 0;
					right: 0;
					height: 3px;
					background: $blue;
				}

				&.toggle_recherche {
					font-size: 0;
					width: 21px;
					background: url(../img/icon_loupe_black.png) center no-repeat;
					background-size: 21px auto;
				}

				&.logout {
					font-size: 0;
					width: 21px;
					background: url(../img/icon_logout.png) center no-repeat;
					background-size: 21px auto;
				}
			}
		}

		@include breakpoint(medium down) {
			position: absolute;
			top: 70px;
			background: $white;
			text-align: left;
			padding: 30px;
			height: calc(100vh - 70px);
			border-right: 1px solid $greyBorder;
			width: 300px;
			left: -300px;
			max-width: 100vw;
			@include transitionType(left, 0.5s);

			&.toggled {
				left: 0;
			}

			li {
				display: block;
				margin-right: 0;

				&:not(:last-child) {
					margin-bottom: 30px;
				}

				a {
					line-height: 30px !important;

					&.toggle_recherche {
						display: none;
					}
				}
			}
		}
	}

	#burger {

		@include breakpoint(large up) {
			display: none;
		}
	}

	#mobile_search_toggle  {
		display: inline-block;
		width: 21px;
		height: 69px;
		margin-right: 50px;
		background: url(../img/icon_loupe_black.png) center no-repeat;
		background-size: 21px auto;

		@include breakpoint(large up) {
			display: none;
		}
	}
	
	&:not(.front) {
		background: $white;
	}

	#header_inner {
		@include transitionType(top, 0.3s);
		background: $white;

		&.fixed {
			position: fixed;
			z-index: 999;
			left: 0;
			right: 0;
			top: -71px;
			background: $white;
			border-bottom: 1px solid $greyBorder;

			nav li a {
				line-height: 69px;
			}

			#logo {
				line-height: 69px;

				img {
					width: 50px;
				}
			}

			@include breakpoint(medium down) {
				top: 0;
			}

			&.mini {
				top: 0;
			}
		}
	}
}

body.front #header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: none;
	height: auto;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	@include transition(0.3s);

	nav li a {
		color: $white;
		@include transition(0.3s);

		&.toggle_recherche {
			background-image: url(../img/icon_loupe_white.png);
		}

		&.logout {
			background-image: url(../img/icon_logout_white.png);
		}
	}

	#logo img {
		@include transition(0.3s);
				
		&.black {
			display: none;
		}

		&.white {
			display: inline-block;
		}
	}

	#header_inner {
		@include transition(0.3s);
		background: none;

		&.fixed {
			top: 0;
			background: $white;

			#logo img {
				
				&.black {
					display: inline-block;
				}

				&.white {
					display: none;
				}
			}

			nav li a {
				color: $black;

				&.toggle_recherche {
					background-image: url(../img/icon_loupe_black.png);
				}

				&.logout {
					background-image: url(../img/icon_logout.png);
				}
			}
		}
	}
}

body:not(.page-recherche) {

	#header_recherche {
		background: $white;
		height: 80px;
		line-height: 78px;
		border: 1px solid $greyBorder;
		@include clearfix();
		position: absolute;
		left: 0;
		right: 0;
		bottom: -80px;
		@include transitionType(opacity, 0.3s);
		pointer-events: none;
		opacity: 0;
		z-index: -1;

		&.toggled {
			opacity: 1;
			pointer-events: all;
		}

		&.mini,
		&.fixed {
			bottom: -150px;
		}

		form {
			float: left;
			width: calc(100% - 98px);
			@include clearfix();

			@include breakpoint(small down) {
				width: calc(100% - 50px);
			}
		}

		#close_search {
			float: right;
			cursor: pointer;

			img {
				width: 18px;
				height: 18px;
				vertical-align: middle;
			}
		}
	}
}

body:not(.front) #header_recherche {	

	&.mini.toggled {
		position: fixed;
		top: 70px;
		left: 0;
		right: 0;
	}
}

body.page-recherche #header nav li.toggle_recherche,
body.page-user #header nav li a.connexion {
	pointer-events: none;
}

body.adminimal-menu #header #header_inner.fixed.mini,
body.front.adminimal-menu #header,
body.front.adminimal-menu #header #header_inner.fixed {
	top: 29px;
}

body.adminimal-menu:not(.front) #header_recherche.mini.toggled {
	top: 98px;
}