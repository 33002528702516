.rte {

	em {
		font-style: italic;
	}
	
	strong {
		font-weight: 600;
	}
	
	p {
		font-size: 15px;
		line-height: 30px;

		@include breakpoint(small down) {
			font-size: 12px !important;
			line-height: 25px !important;
		}

		&:not(:last-child):not(.no_margin) {
			margin-bottom: 30px;

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}

		a {
			color: $blue;
			text-decoration: underline;

			&:hover {
				color: $greyText;
			}
		}

		img {
			max-width: 100%;
			height: auto;
			display: block;
			//margin: auto;

			@include breakpoint(small down) {
				float: none!important;
				margin-left: 0!important;
				margin-right: 0!important;
			}
		}

		&.bouton a {
			@include button($black, $blue);
			text-decoration: none;
		}

		.file .file-icon {
			display: none;
		}

		iframe {
			max-width: 100%;
			margin: auto;
			display: block;
		}

		&.no_margin + h2,
		&.no_margin + h3,
		&.no_margin + h4,
		&.no_margin + ul,
		&.no_margin + p:not(.no_margin) {
			margin-top: 30px;
		}
	}

	ul {		

		&:not(:last-child) {
			margin-bottom: 30px;

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}

		li {
			position: relative;			
			font-size: 15px;
			line-height: 30px;
			padding-left: 35px;
			position: relative;

			@include breakpoint(small down) {
				font-size: 12px;
				line-height: 25px;
				padding-left: 25px;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 13px;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: $blue;

				@include breakpoint(small down) {
					top: 10px;
				}
			}

			a {
				color: $blue;
				text-decoration: underline;

				&:hover {
					color: $greyText;
				}
			}
		}
	}

	h2 {
		color: $blue;
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 30px;
		line-height: 40px;

		@include breakpoint(small down) {
			font-size: 15px;
			line-height: 30px;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 30px;
		line-height: 30px;

		@include breakpoint(small down) {
			font-size: 14px;
			line-height: 30px;
			margin-bottom: 20px;
		}
	}

	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 30px;
		line-height: 30px;

		@include breakpoint(small down) {
			font-size: 13px;
			line-height: 30px;
			margin-bottom: 20px;
		}
	}

	.media-element-container img {
		max-width: 100%;
		height: auto;
	}

	@include breakpoint(small down) {

		.media-wysiwyg-align-left {
			float: none;
			margin-right: 0;
		}

		.media-wysiwyg-align-right {
			float: none;
			margin-left: 0;
		}
	}

}
