#slider_recommandations {
	@include row();

	> .flex-control-thumbs {
		@include grid-column(5, $gutters);
		float: left;
		margin: 0;
		position: relative;
		bottom: 0;

		@include breakpoint(medium up) {

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 10px;
				bottom: 0;
				width: 5px;
				background: $greyBg;
			}
		}

		@include breakpoint(small down) {
			width: 100%;
			margin-top: 40px;
		}

		li {
			float: none;
			display: block;
			padding-left: 100px;
			width: 230px;
			text-align: center;
			position: relative;

			@include breakpoint(medium up) {				

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				.border {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 10px;
					@include transition(0.3s);
				}
			}

			@include breakpoint(medium down) {
				padding-left: 30px;
			}

			@include breakpoint(small down) {
				display: inline-block;
				width: auto;
				padding-left: 0;

				&:not(:last-child) {
					margin-right: 15px;
				}
			}

			img {
				width: 70px;
				height: auto;
				border-radius: 50%;
				margin: auto;
				@include transition(0.3s);

				@include breakpoint(medium up) {

					&.flex-active {
						width: 130px;
						height: auto;

						&+ .border {
							background: $blue;
						}
					}
				}

				@include breakpoint(small down) {
					width: 50px;
				}
			}
		}
	}

	> .slides {
		@include grid-column(7, $gutters);
		float: right;

		@include breakpoint(small down) {
			width: 100%;
		}

		.nom {
			font-size: 14px;
			//text-transform: uppercase;
			line-height: 20px;
			letter-spacing: 1.4px;
			margin-bottom: 30px;

			@include breakpoint(small down) {
				font-size: 12px;
				margin-bottom: 15px;
			}
		}

		h2 {
			font-size: 30px;
			//text-transform: uppercase;
			line-height: 50px;
			font-weight: 700;
			margin-bottom: 40px;

			@include breakpoint(medium down) {
				font-size: 25px;
				line-height: 40px;
			}

			@include breakpoint(small down) {
				font-size: 20px;
				line-height: 35px;
				margin-bottom: 20px;
			}
		}

		.contenu {
			font-size: 16px;
			line-height: 35px;
			margin-bottom: 40px;

			@include breakpoint(medium down) {
				font-size: 14px;
				line-height: 30px;
			}

			@include breakpoint(small down) {
				font-size: 12px;
				line-height: 25px;
				margin-bottom: 20px;
			}
		}

		i {
			display: block;
			text-align: right;

			img {
				width: 62px;
				height: auto;
				display: inline-block;
			}
		}
	}
}