#dialogLogin .dialog__content {
	width: 950px;
	@include clearfix();

	@include breakpoint(medium down) {
		max-width: 90vw;
	}
	
	#login_left {
		float: left;
		width: 368px;
		font-size: 0;

		@include breakpoint(medium down) {
			display: none;
		}
	}	

	#login_right {
		float: left;
		width: calc(100% - 368px);
		padding-top: 65px;
		text-align: left;
		padding-left: 60px;
		padding-right: 60px;

		@include breakpoint(medium down) {
			width: 100%;
			padding-bottom: 65px;
			padding-left: 15px;
			padding-right: 15px;
		}

		h2 {
			font-size: 30px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 35px;

			@include breakpoint(small down) {
				font-size: 20px;
				margin-bottom: 30px;
			}
		}

		p.contact {
			margin-top: 30px;
			font-size: 15px;
			line-height: 22px;
			font-weight: 600;

			a {
				color: $blue;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}