#page_fournisseurs_liste {
	min-height: 300px;

	form {
		@include clearfix();
		margin-bottom: 70px;

		@include breakpoint(medium down) {
			margin: 30px 0;
		}

		> div {
			@include grid-column(6, 0);
			background: $blue;

			@include breakpoint(large up) {
				margin-top: -150px;
				padding: 70px;
			}

			@include breakpoint(medium down) {
				width: 100%;
				padding: 35px;
			}

			label {
				display: block;
				text-align: center;
				color: $white;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 20px;
			}

			.views-exposed-widget {
				padding: 0;
				float: none;
			}

			.views-submit-button {
				display: none;
			}
		}
	}
}