#accueil_banniere {
	position: relative;

	figure {
		font-size: 0;
		overflow: hidden;;
		
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.4);
		}

		img {
			opacity: 0;
		}

		@include breakpoint(large down) {
			background-attachment: unset !important;
		}

		@include breakpoint(small down) {

			img {
				max-height: 100vh;
				width: auto;
			}
		}
	}

	.container {
		position: absolute;
	  	margin: auto;
	  	top: 0;
	  	bottom: 0;
	  	left: 0;
	  	right: 0;
	  	//height: 100%;
	  	//width: 100%;
	  	//display: table;
	  	max-width: 100%;
	  	line-height: 100vh;
	  	text-align: center;
	  	color: $white;

	  	article {
	  		//display: table-cell;
	  		display: inline-block;
	  		vertical-align: middle;
	  		line-height: 1;

		  	h1 {
		  		font-size: 40px;
		  		font-weight: 700;
		  		line-height: 40px;
		  		margin-bottom: 40px;

				@include breakpoint(small down) {
					font-size: 24px;
				}
		  	}

		  	.sous_titre {
		  		font-size: 15px;
		  		line-height: 20px;
		  		font-weight: 600;
		  		margin-bottom: 40px;

				@include breakpoint(small down) {
					font-size: 12px;
				}
		  	}

		  	.bouton a {
		  		@include button(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
		  		font-weight: 600;
		  	}
	  	}
	}
}

#accueil_contenu {
	padding: 90px 0;

	@include breakpoint(small down) {
		padding: 30px 0;
	}

	.sur_titre {
		//font-size: 18px;
		font-size: 30px;
		line-height: 40px;
	    //font-weight: 600;
	    font-weight: 700;
	    /* line-height: 20px; */
	    margin-bottom: 30px;
	    letter-spacing: 1px;
	    background: #24638c;
	    padding: 15px;
	    color: white;

		@include breakpoint(medium down) {
			font-size: 20px;
			line-height: 30px;
		}

		@include breakpoint(small down) {
			font-size: 16px;
			line-height: 25px;
		}
	}

	h2 {
		//text-transform: uppercase;
		font-size: 30px;
		font-weight: 700;
		line-height: 50px;

		@include breakpoint(small down) {
			font-size: 20px;
			line-height: 35px;
		}
	}

	#contenu_left{
		@include grid-column(5, $gutters);
		padding-top: 50px;

		@include breakpoint(medium down) {
			width: 100%;
			padding-top: 0;
			margin-bottom: 30px;
		}

		h2 {
			margin-bottom: 20px;

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}

		.contenu {
			//font-size: 14px;
			//line-height: 30px;
			margin-bottom: 20px;

			@include breakpoint(small down) {
				//font-size: 12px;
				//line-height: 25px;
			}
		}

		.bouton {

			@include breakpoint(medium down) {
				text-align: center;
			}

			a {
				@include button($black, $blue);
				font-weight: 600;
			}
		}
	}

	#contenu_right {
		@include grid-column(5, $gutters);
		float: right;

		@include breakpoint(medium down) {
			width: 100%;
		}

		figure {
			position: relative;
			font-size: 0;

			@include breakpoint(medium down) {

				img {
					width: 100%;
					height: auto;
				}
			}

			.image_texte {
				position: absolute;
				top: 50px;
				left: -125px;
				color: $white;
				//text-transform: uppercase;
				font-size: 25px;
				font-weight: 700;
				line-height: 40px;
				padding: 45px;
				width: 320px;
				background: $blue;

				@include breakpoint(medium down) {
					position: static;
					width: 100%;
					font-size: 20px;
					line-height: 35px;
					padding: 35px;
				}

				@include breakpoint(small down) {
					font-size: 14px;
					line-height: 20px;
					padding: 15px;
				}
			}
		}
	}

	#colonnes_titre {
		@include grid-column(12, $gutters);
		text-align: center;
		padding-top: 75px;
		padding-bottom: 75px;


		@include breakpoint(small down) {
			padding-top: 50px;
			padding-bottom: 30px;
			text-align: left;
		}
	}

	#accueil_colonnes {
		clear: both;
		@include clearfix();

		li {			
			@include grid-column(4, $gutters);
			line-height: 30px;

			@include breakpoint(medium down) {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}

			h3 {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 35px;

				@include breakpoint(small down) {
					font-size: 15px;
					margin-bottom: 20px;
				}
			}

			p {
				font-size: 14px;

				@include breakpoint(small down) {
					font-size: 12px;
					line-height: 25px;
				}
			}
		}
	}
}

#accueil_partenaires {

	@include breakpoint(medium up) {
		padding-bottom: 100px;
	}

	> figure {
		font-size: 0;

		@include breakpoint(medium down) {
			background-attachment: unset !important;
		}

		img {
			max-width: 100%;
			height: auto;
			opacity: 0;
		}
	}

	> .container {
		position: relative;
		background: $white;
		padding: 35px;
		padding-bottom: 60px;

		@include breakpoint(medium up) {
			margin-top: -125px;			
			box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
		}

		h2 {
			margin-bottom: 20px;
			font-size: 14px;
			line-height: 50px;
			//text-transform: uppercase;
			letter-spacing: 1.4px;
		}

		.liste_partenaires {
			text-align: center;
			@include row();

			li {
				@include grid-column(3, $gutters);

				@include breakpoint(small down) {
					width: 50%;
				}

				figure {
					text-align: center;
					height: 85px;
					line-height: 85px;

					img {
						max-width: 80%;
						max-height: 100%;
						width: auto;
						height: auto;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

#accueil_recommandations {

	h2 {
		//text-transform: uppercase;
		font-size: 30px;
		font-weight: 700;
		line-height: 50px;
		margin-bottom: 30px;

		@include breakpoint(small down) {
			font-size: 20px;
			line-height: 35px;
			margin-bottom: 15px;
			margin-top: 20px;
		}
	}
}