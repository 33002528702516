.page-recherche {

	#header {
		height: 359px;
		border-bottom: 0;

		@include breakpoint(medium down) {
			height: 319px;
		}

		@include breakpoint(small down) {
			height: 225px;
		}

		#header_recherche {
		    position: absolute;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    z-index: -1;
			border-top: 1px solid $greyBorder;
			background: $greyBg;
			padding: 50px 0;
			text-align: left;

			@include breakpoint(small down) {
				padding: 30px 0;
			}

			#close_search {
				display: none;
			}
		}
	}

	#page {
		padding-top: 40px;

		#nombre_resultats {
			font-size: 18px;
			line-height: 30px;
			color: $greyText;
			margin-bottom: 50px;
		}
	}
}