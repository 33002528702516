#page_node_banner {

	&.border {
		border-bottom: 1px solid $greyBorder;
	}

	@include breakpoint(large up) {

		&.padding_top {
			padding-top: 60px;
		}
	}

	&:not(.no_margin) {
		margin-bottom: 60px;

		@include breakpoint(small down) {
			margin-bottom: 0;
		}
	}

	.container {
		@include clearfix();

		@include breakpoint(medium down) {
			max-width: 100%;
		}

		figure {
			@include clearfix();
			font-size: 0;

			@include breakpoint(large up) {
				@include grid-column(7, 0);

				img {
					float: right;
				}
			}

			@include breakpoint(medium down) {
				text-align: center;

				img {
					max-width: 100%;
					height: auto;
				}
			}
		}

		article {
			padding-top: 80px;
			//text-align: right;

			@include breakpoint(large up) {

				&.small_padding {
					padding-top: 35px;
				}
			}

			@include breakpoint(large up) {
				@include grid-column(5, 0);
				padding-left: 60px;
			}

			@include breakpoint(large down) {
				padding-top: 40px;
			}

			@include breakpoint(medium down) {
				@include container();
				background: $white;
				margin-left: auto;
				text-align: center;
				padding-left: 0;
				padding: 30px;
				position: relative;
				margin-top: -150px;
			}

			@include breakpoint(small down) {
				padding: 0;
				padding-bottom: 30px;
				margin-top: 30px;
			}

			h1 {
				font-size: 30px;
				font-weight: 700;
				line-height: 50px;
				margin-bottom: 50px;
				@include clearfix();

				&:not(.lowercase) {
					text-transform: uppercase;					
				}

				@include breakpoint(medium down) {
					height: auto;
					margin-bottom: 30px;
				}

				@include breakpoint(small down) {
					font-size: 20px;
					line-height: 30px;
					margin-bottom: 15px;
				}
			}

			.sous_titre {
				font-size: 14px;
				letter-spacing: 1.4;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 65px;
			}

			.rte p {
				font-size: 14px;

				&.bouton a {
					font-weight: 600;
				}
			}
		}
	}

	@include breakpoint(large up) {

		&.align_right .container {

			figure {
				float: right;

				img {
					float: none;
				}
			}

			article {
				text-align: left;
				padding-left: 0;
				padding-right: 60px;
			}
		}
	}

	&.recommandations .container {
		padding-top: 80px;

		@include breakpoint(large up) {
			margin-bottom: 220px;
		}

		figure {
			position: relative;

			@include breakpoint(large up) {
				@include grid-column(5, $gutters);
			}

			.image_texte {
				position: absolute;
				bottom: -150px;
				right: 160px;
				color: $white;
				text-transform: uppercase;
				font-size: 25px;
				font-weight: 700;
				line-height: 40px;
				padding: 45px;
				width: 320px;
				background: $blue;

				@include breakpoint(medium down) {
					width: 100%;
					font-size: 20px;
					line-height: 35px;
					padding: 35px;
				    max-width: 600px;
				    left: 0;
				    right: 0;
				    margin: auto;
				    bottom: 150px;
				}

				@include breakpoint(small down) {
					position: static;
					font-size: 14px;
					line-height: 20px;
					padding: 15px;
				}
			}
		}

		article {

			@include breakpoint(large up) {
				@include grid-column(7, 0);
				text-align: left;
				padding: 0 100px;
				padding-top: 30px;
			}

			@include breakpoint(large down) {
				padding: 20px;
			}

			h1 {
				margin-bottom: 25px;
			}
		}
	}
}