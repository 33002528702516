ul.pager {
	text-align: center;
	height: 60px;
	line-height: 60px;
	font-size: 0;
	position: relative;
	padding: 0 200px;
	margin-top: 60px;
	@include clearfix();

	@include breakpoint(medium down) {
		padding: 0;
		margin-top: 10px;
	}

	li {
		display: inline-block;
		font-size: 16px;
		width: 60px;
		font-weight: 600;
		margin: 0 5px;

		a {
			display: block;
			color: $black;
			background: $white;

			&:hover {
				background: $blue;
				color: $white;
			}
		}

		&.pager-current {
			background: $blue;
			color: $white;
		}

		&.pager-first,
		&.pager-last {
			display: none;
		}

		&.pager-previous,
		&.pager-next {
			position: absolute;
			top: 0;
			margin: 0;

			@include breakpoint(medium down) {
				display: none;
			}

			a {
				color: $white;
				width: 200px;
				text-align: center;
				color: $white;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 0.7px;
				background: $black;

				&:hover {
					background: $blue;
				}
			}
		}

		&.pager-previous {
			left: 0;
		}

		&.pager-next {
			right: 0;
		}
	}
}