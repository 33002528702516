#section_error {
	min-height: 100vh;
	line-height: 100vh;
	background: url(../img/background_erreur.png) center no-repeat;
	background-size: cover;
	position: relative;
	z-index: 1;
	text-align: center;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: $black;
		opacity: 0.8;
	}

	.error_page_content {
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
		width: 100%;
		color: $white;

		h1 {
			font-size: 100px;
			margin-bottom: 75px;
			font-family: $ffP;
			font-weight: 700;
			color: $white;
		}

		p {
			font-size: 30px;

			&.texte_big {
				font-size: 40px;
				margin-bottom: 50px;
				font-weight: 500;
			}
		}

		.bouton {
			margin-top: 50px;

			a {
				@include button($blue, $black);
				text-transform: uppercase;
			}
		}

		@include breakpoint(small down) {

			h1 {
				font-size: 50px;
			}

			p {
				font-size: 15px;

				&.texte_big {
					font-size: 20px;
				}
			}

			.bouton {

				a {
					font-size: 12px;
				}
			}
		}
	}
}