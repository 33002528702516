#page.contact,
#page.user {

	@include breakpoint(large up) {
		padding-top: 60px;
	}

	#contact_left,
	#user_left {
		@include grid-column(6, $gutters);
		padding-top: 30px;

		@include breakpoint(large up) {
			padding-right: 110px;
		}

		@include breakpoint(medium down) {
			width: 100%;
		}

		h1 {
			font-size: 30px;
			font-weight: 700;
			line-height: 50px;
			margin-bottom: 50px;
			text-transform: uppercase;

			@include breakpoint(medium down) {
				margin-bottom: 30px;
			}

			@include breakpoint(small down) {
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 15px;
			}
		}

		.description {
			font-size: 14px;
			line-height: 30px;
			margin-bottom: 50px;

			@include breakpoint(small down) {
				font-size: 12px;
				line-height: 25px;
				margin-bottom: 30px;
			}
		}

		.telephone {
			padding-left: 40px;
			font-size: 20px;
			line-height: 30px;
			font-weight: 900;
			background: url(../img/icon_phone.png) left center no-repeat;
			background-size: 19px auto;
			margin-bottom: 10px;

			@include breakpoint(small down) {
				font-size: 16px;
			}
		}

		.horaires {
			padding-left: 40px;
			text-transform: uppercase;
			font-size: 15px;
			line-height: 20px;
			font-weight: 700;
			margin-bottom: 50px;

			@include breakpoint(small down) {
				font-size: 14px;
				margin-bottom: 30px;
			}
		}

		p.bouton a {
			@include button($black, $blue);
			margin-bottom: 100px;

			@include breakpoint(medium down) {
				margin-bottom: 30px;
			}
		}

		figure {
			font-size: 0;

			@include breakpoint(medium down) {
				margin-bottom: 30px;
			}

			img {
				position: relative;
				left: -141px;

				@include breakpoint(medium down) {
					position: static;
					width: 100%;
					height: auto;
				}
			}
		}

		.menu {
			margin-bottom: 60px;
			@include clearfix();

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}

			li {
				float: left;

				&:not(:last-child) {
					padding-right: 32px;
				}

				a {
					display: block;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					font-size: 0;

					&.linkedin {
						background: url(../img/logo_linkedin.png) center no-repeat $blue;
						background-size: 16px auto;
					}

					&.facebook {
						background: url(../img/logo_facebook.png) center no-repeat $blue;
						background-size: 9px auto;
					}

					&:hover {
						background-color: $black;
					}
				}
			}
		}
	}

	#contact_right,
	#user_right {
		@include grid-column(6, $gutters);
		position: relative;

		@include breakpoint(medium down) {
			width: 100%;
		}

		form {
			background: $blue;
			position: absolute;
			padding-left: 100px;
			padding-top: 60px;
			padding-bottom: 45px;
			left: 10px;
			top: 0;
			right: 10px;
			z-index: 1;

			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 100px;
				top: 0;
				bottom: 0;
				right: -100px;
				background: $blue;
			}

			@include breakpoint(large down) {
				padding-left: 30px;
			}

			@include breakpoint(medium down) {
				position: static;
				padding: 30px;
				margin-bottom: 30px;

				&:before {
					content: none;
				}
			}

	        @include breakpoint(small down) {
	        	padding: 30px 15px;
	        }
		}

		p {
			color: $white;
			font-size: 16px;
			font-weight: 500;
			line-height: 30px;
			margin-bottom: 20px;
		}
	}

	#contact_visuel {
		font-size: 0;
		height: 415px;
		position: relative;

		img {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -999px;
			right: -999px;
			margin: auto;
			z-index: 0;
		}

		@include breakpoint(medium down) {
			height: 250px;

			img {
				height: 250px;
				width: auto;
			}
		}
	}
}

#page.contact {
	padding-bottom: 0;
}