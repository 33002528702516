#page_banner {
	@include clearfix();
	font-size: 0;
	margin-bottom: 60px;

	@include breakpoint(medium down) {
		max-width: 100%;
	}

	@include breakpoint(small down) {
		padding: 0;
		margin-bottom: 30px;
	}

	h1 {
		@include grid-column(5, 0);
		height: 500px;
		background: $blue;
		padding-top: 80px;
		padding-right: 50px;
		color: $white;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 50px;
		font-weight: 600;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			width: calc(50vw - 1144px / 2);
			left: calc(-50vw + 1144px / 2);
			background: $blue;
		}

		@include breakpoint(large down) {
			font-size: 26px;
			padding-top: 60px;

			&:before {
				width: calc(50vw - 900px / 2);
				left: calc(-50vw + 900px / 2);
			}
		}

		@include breakpoint(medium down) {
			width: 100%;
			float: none !important;
			height: auto;
			padding: 30px 0;
			background: $blue;
			font-size: 24px;
			line-height: 40px;

			&:before {
				content: none;
			}

			span {				
				@include container();
				display: block;
			}
		}

		@include breakpoint(small down) {
			font-size: 18px;
			line-height: 25px;
		}
	}

	figure {
		@include grid-column(7, 0);
		float: right;

		@include breakpoint(medium down) {
			width: 100%;
			float: none;
			max-height: 300px;
			overflow: hidden;

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}