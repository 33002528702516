article#entreprise {
	padding-top: 60px;

	@include breakpoint(small down) {
		padding-top: 30px;
	}

	#entreprise_banner {
		@include clearfix();
		margin-bottom: 60px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}

		#entreprise_banner_left {

			@include breakpoint(large up) {
				width: 330px;
				float: left;
				position: relative;
				height: 530px;
			}

			@include breakpoint(medium down) {

			}

			.lien_retour {
				@include backlink();

				@include breakpoint(medium down) {
					margin-bottom: 30px;
				}
			}

			figure {
				margin: auto;
				height: 330px;
				width: 330px;
				border: 1px solid $greyBorder;
				text-align: center;
				line-height: 330px;
				background: $white;

				@include breakpoint(large up) {
					position: absolute;
					z-index: 1;
					top: 0;
					bottom: 0;
					left: 136px;
				}

				@include breakpoint(large only) {
					left: 65px;
				}

				@include breakpoint(medium down) {
					margin-bottom: -100px;
					position: relative;
					z-index: 1;
					width: 280px;
					height: 280px;
					line-height: 280px;

					img {
						max-width: 90%;
						max-height: 90%;
						width: auto;
						height: auto;
					}
				}

				@include breakpoint(small down) {
					margin-bottom: -75px;
					position: relative;
					z-index: 1;
					width: 150px;
					height: 150px;
					line-height: 150px;
				}

				img {
					vertical-align: middle;
				}
			}
		}

		#entreprise_banner_right {
			position: relative;
			background: $blue;

			@include breakpoint(large up) {
				width: calc(100% - 330px);
				float: right;
				height: 530px;
				padding-left: 288px;
				padding-top: 90px;
			}

			@include breakpoint(large only) {
				padding-left: 144px;
			}

			@include breakpoint(medium down) {
				padding-bottom: 30px;
				padding-top: 130px;
				text-align: center;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					width: calc(50vw - 600px / 2);
					left: calc(-50vw + 600px / 2);
					background: $blue;
				}
			}

			@include breakpoint(small down) {
				padding-top: 105px;

				&:before {					
					width: 10px;
					left: -10px;
				}
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: calc(50vw - 1144px / 2);
				right: calc(-50vw + 1144px / 2);
				background: $blue;

				@include breakpoint(large down) {
					width: calc(50vw - 900px / 2);
					right: calc(-50vw + 900px / 2);
				}

				@include breakpoint(medium down) {
					width: calc(50vw - 600px / 2);
					right: calc(-50vw + 600px / 2);
				}

				@include breakpoint(small down) {
					width: 10px;
					right: -10px;
				}
			}

			h1 {
				font-size: 30px;
				font-weight: 700;
				text-transform: uppercase;
				color: $white;
				margin-bottom: 20px;
				line-height: 40px;

				@include breakpoint(small down) {
					font-size: 18px;
					line-height: 25px;
					margin-bottom: 15px;
				}
			}

			.categorie {
				color: $white;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 40px;
				line-height: 25px;
				letter-spacing: 1px;

				@include breakpoint(small down) {
					font-size: 12px;
					line-height: 20px;
					margin-bottom: 30px;
				}
			}

			.entreprise_infos {
				margin-bottom: 52px;

				@include breakpoint(medium down) {
					margin-left: auto;
					margin-right: auto;
					width: 330px;
					max-width: 100%;
					text-align: left;
				}

				li {
					padding-left: 45px;
					color: $white;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					word-wrap: break-word;

					&:not(:last-child) {
						margin-bottom: 20px;
					}

					@include breakpoint(small down) {
						font-size: 14px;

						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}

					&.telephone {
						background: url(../img/icon_phone.png) left center no-repeat;
						background-size: 19px auto;
					}

					&.email {
						background: url(../img/icon_mail.png) left center no-repeat;
						background-size: 21px auto;
					}

					&.site {
						background: url(../img/icon_web.png) left center no-repeat;
						background-size: 19px auto;
					}

					&.contact {
						background: url(../img/icon_person.png) left 3px center no-repeat;
						background-size: 15px auto;
					}

					a {
						color: $white;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.evaluer {
				@include button($black, $lightBlack);
				font-weight: 600;
				letter-spacing: 0;
			}
		}
	}

	#entreprise_recommandations {
		padding-top: 120px;

		@include breakpoint(medium down) {
			padding-top: 60px;
		}
	}
}