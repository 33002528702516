.liste_fournisseurs {
	@include row();

	&:hover > li:not(:hover) {
		opacity: 0.5;
	}

	> li {
		@include grid-column(4, $gutters);
		margin-bottom: 20px;
		@include transition(0.3s);

		@include breakpoint(xlarge up) {
			
			&:nth-child(3n+4) {
				clear: left;
			}
		}

		@include breakpoint(large down) {
			width: 50%;
			
			&:nth-child(2n+3) {
				clear: left;
			}
		}

		@include breakpoint(small down) {
			width: 100%;
			
			&:nth-child(3n+4) {
				clear: none;
			}
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		.bg {
			display: block;
			border: 1px solid $greyBorder;
			background: $greyBg;
			color: $black;
			padding-bottom: 15px;
			position: relative;

			figure {
				border-bottom: 1px solid $greyBorder;
				background: $white;
				height: 184px;
				line-height: 184px;
				text-align: center;
				margin-bottom: 30px;

				@include breakpoint(medium down) {
					margin-bottom: 15px;
				}

				img {
					vertical-align: middle;
					max-width: 90%;
					max-height: 90%;
					width: auto;
					height: auto;
				}
			}

			h2 {
				padding: 0 35px;
				height: 54px;
				margin-bottom: 4px;
				text-transform: uppercase;
				font-size: 20px;
				line-height: 25px;
				font-weight: 700;
				overflow: hidden;
				color: $black;
				@include transition(0.3s);

				&:hover {
					color: $blue;
				}

				@include breakpoint(medium down) {
					font-size: 16px;
					padding-left: 15px;
				}
			}

			.categorie {
				padding: 0 35px;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 1px;
				line-height: 20px;
				min-height: 100px;
				overflow: hidden;

				@include breakpoint(medium down) {
					font-size: 12px;
					padding-left: 15px;
				}

				span {
					display: block;
				}
			}

			.telephone,
			.email {
				padding-left: 35px;
				padding-right: 70px;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.5px;
				word-wrap: break-word;

				@include breakpoint(medium down) {
					font-size: 12px;
					padding-left: 15px;
				}
			}

			.telephone {
				margin-bottom: 4px;
				height:	20px;
			}

			.email {
				height: 40px;

				a{
					color: $black;

					&:hover {
						color: $blue;
					}
				}
			}

			.voir_plus {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 60px;
				height: 60px;
				background: url(../img/icon_plus.png) center no-repeat $blue;
				background-size: 22px auto;
				@include transition(0.3s);

				&:hover {
					background-color: $lightBlack;
				}
			}
		}
	}
}