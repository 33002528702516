@mixin button($bgColor, $bgColorHover) {
	display: inline-block;
	font-size: 15px;
	line-height: 24px;
	text-transform: uppercase;
	color: $white;
	padding: 13px 35px;
	background: $bgColor;
	letter-spacing: 0.5px;
	cursor: pointer;
	@include transition(0.3s);

	&:hover {
		background: $bgColorHover;
	}

	@include breakpoint(small down) {
		font-size: 12px;
		padding: 10px 30px;
	}
}

@mixin backlink() {
	display: inline-block;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	color: $black;
	padding-left: 35px;
	background: url(../img/icon_arrow.png) left center no-repeat;
	background-size: 12px auto;
	letter-spacing: 0.5px;

	&:hover {
		color: $blue;
	}

	@include breakpoint(small down) {
		font-size: 12px;
		padding-left: 25px;
	}
}