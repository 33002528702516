#footer {
	background: $greyBg;
	padding-top: 90px;
	padding-bottom: 71px;

	@include breakpoint(small down) {
		text-align: center;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	h2 {
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 45px;
		letter-spacing: 1px;

		@include breakpoint(small down) {
			margin-bottom: 20px;
			text-align: center;
		}
	}

	.footer_logo {
		@include grid-column(3, $gutters);

		@include breakpoint(large down) {
			width: calc(100% / 3);
		}

		@include breakpoint(small down) {
			width: 100%;
			margin-bottom: 30px;
		}

		img {
			width: 170px;
			height: auto;
		}
	}

	.footer_menu {
		@include grid-column(2, $gutters);

		@include breakpoint(large down) {
			width: calc(100% / 3);
		}

		@include breakpoint(small down) {
			width: 100%;
			margin-bottom: 30px;
		}

		li {

			&:not(:last-child) {
				margin-bottom: 15px;
			}

			a {
				font-size: 15px;
				color: $lightBlack;
				line-height: 20px;

				&:hover {
					color: $blue;
				}
			}
		}
	}

	.footer_social {
		@include grid-column(3, $gutters);
		font-size: 0;

		@include breakpoint(large down) {
			width: calc(100% / 3);
		}

		@include breakpoint(small down) {
			width: 100%;
			margin-bottom: 30px;
		}

		li {
			display: inline-block;
			vertical-align: top;

			&:not(:last-child) {
				margin-right: 20px;
			}

			a {
				display: block;
				width: 76px;
				height: 76px;

				&.linkedin {
					background: url(../img/logo_linkedin.png) center no-repeat $blue;
					background-size: 35px auto;
				}

				&.facebook {
					background: url(../img/logo_facebook.png) center no-repeat $blue;
					background-size: 15px auto;
				}

				&:hover {
					background-color: $lightBlack;
				}
			}
		}
	}

	.footer_newsletter {
		@include grid-column(4, $gutters);
		text-align: left;

		@include breakpoint(large down) {
			width: 100%;
			margin-top: 30px;
			margin-bottom: 60px;
		}

		@include breakpoint(small down) {
			margin-top: 0;
			margin-bottom: 30px;
		}
	}

	.footer_bottom {
		font-size: 14px;
		font-weight: 600;
		color: $black;

		a {
			color: $black;

			&:hover {
				color: $blue;
			}
		}
	}
}

body.logged-in #footer .footer_menu li a.connexion {
	display: none;
}