#skip-link{
	a{
		display: block; 
		overflow: hidden;
		height: 0;
		width: 100%; 
		background: $secondColor; 
	
		line-height: 25px; 
		text-transform: uppercase;
		color: #fff;  
		font-size: 14px;
		text-align: center;
		
		
		&:focus{
			height: 25px; 
		}
	}
}