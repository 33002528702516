.liste_documents {
	padding-top: 70px;
	@include row();

	@include breakpoint(small down) {
		padding-top: 30px;
	}

	&:hover > li:not(:hover) {
		opacity: 0.5;
	}

	> li {
		@include grid-column(3, $gutters);
		margin-bottom: 20px;
		@include transition(0.3s);

		@include breakpoint(large down) {
			@include grid-column(4, $gutters);
		}

		@include breakpoint(medium down) {
			@include grid-column(6, $gutters);
		}

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		article {
			border: 1px solid $greyBorder;

			figure {
				font-size: 0;
				margin-bottom: 25px;

				img {
					width: 100%;
					height: auto;
				}
			}

			h2 {
				font-size: 16px;
				line-height: 30px;
				font-weight: 600;
				padding: 0 25px;
				height: 90px;
				overflow: hidden;
			}

			.document_liens {
				text-align: right;
				font-size: 0;

				li {
					display: inline-block;

					a {
						display: block;
						width: 70px;
						height: 70px;

						&:hover {
							opacity: 0.8;
						}
					}

					&.voir a {
						background: url(../img/icon_eye.png) center no-repeat $black;
						background-size: 25px auto;
					}

					&.telecharger {

						img {
							display: none;
						}

						a {
							background: url(../img/icon_download.png) center no-repeat $blue;
							background-size: 18px auto;
						}

					}
				}
			}
		}
	}
}