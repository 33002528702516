select + .chosen-container {
	width: 100% !important;
}

select + .chosen-container.chosen-container-single .chosen-single {
    display: block;
    width: 100%;
    min-height: 72px;
    padding: 0 30px;
    padding-left: 10px;
    padding-right: 80px;
    font-size: 16px;
    color: $white;
    vertical-align: middle;
    background: url(../img/icon_chevron_white.png) right 30px top 30px no-repeat $blue;
    background-size: 17px auto;
    border: 1px solid $white;
    cursor: pointer;
    padding-top: 10px;
}

select + .chosen-container.chosen-container-single .chosen-single span {
    display: block;
    height: auto;
    width: 100% !important;
    border: 0;
    background: none;
    font-size: 16px;
    color: $white;
    line-height: 50px;
    cursor: pointer;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

select + .chosen-container .chosen-drop {
    background-color: $white;
    background-clip: padding-box;
    height: 0;
    position: relative;
    z-index: 1;
}

select + .chosen-container:not(.chosen-with-drop) .chosen-drop {
	opacity: 0;
	pointer-events: none;
}

select + .chosen-container .chosen-search {
    display: none;
}

select + .chosen-container .chosen-search input[type=text] {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-image:none;
}

select + .chosen-container .chosen-results {
    font-size: 16px;
    list-style: none;
    background-color: #fff;
    border: 1px solid $white;
    border-top: 0;
    position: relative;
    max-height: 300px;
    overflow: auto;
    border: 1px solid $blue;

    /*&:before {
    	content: "";
    	position: absolute;
    	top: 0;
    	bottom: -2px;
    	left: -2px;
    	right: -2px;
    	background: $blue;
    	z-index: -1;
    }*/
}

select + .chosen-container .chosen-results li , 
select + .chosen-container .chosen-results li.active-result {
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.4;
    color: $blue;
    white-space: nowrap;
    background-image:none;
    line-height: 20px;
    padding: 6px 15px;
    cursor: pointer;
    white-space: pre-wrap;
}
select + .chosen-container .chosen-results li:hover, 
select + .chosen-container .chosen-results li.active-result:hover,
select + .chosen-container .chosen-results li.highlighted
{
    color: #FFF;
    text-decoration: none;
    background-color: $blue;
    background-image:none;
}

select + .chosen-container-multi .chosen-choices {
    display: block;
    width: 100%;
    min-height: 72px;
    padding: 0 30px;
    padding-left: 10px;
    padding-right: 80px;
    font-size: 16px;
    color: $white;
    vertical-align: middle;
    background: url(../img/icon_chevron_white.png) right 30px top 30px no-repeat $blue;
    background-size: 17px auto;
    border: 1px solid $white;
    cursor: pointer;
    padding-top: 10px;
}

select + .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: auto;
    width: 100% !important;
    border: 0;
    background: none;
    font-size: 16px;
    color: $white;
    line-height: 50px;
    cursor: pointer;
    padding-left: 20px;
}

select + .chosen-container-multi .chosen-choices li.search-choice {
    background: $blue;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    border: 1px solid $white;
    color: $white;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 14px 15px;
    padding-right: 32px;
    line-height: 20px;
    position: relative;
    cursor: default;

    &+ .search-field {
    	display: none;
    }

    .search-choice-close {
    	position: absolute;
    	top: 0 !important;
    	bottom: 0;
    	right: 10px !important;
    	margin: auto;
    	width: 11px;
    	height: 11px;
    	background: url(../img/icon_plus.png) center no-repeat;
    	background-size: 11px auto;
    	@include rotate(45deg);
    	cursor: pointer;
    }
}

select + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top:8px;
    right:6px;
}

select + .chosen-container-multi.chosen-container-active .chosen-choices,
select + .chosen-container.chosen-container-single.chosen-container-active .chosen-single,
select + .chosen-container .chosen-search input[type=text]:focus{
    //border-color: #66AFE9;
    outline: 0;
}

select + .chosen-container-multi .chosen-results li.result-selected{
    display: list-item;
    color: #ccc;
    cursor: default;
    background-color: white;
}