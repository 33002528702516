// EFFETS CSS
@mixin transition($duration) {
       -moz-transition: all $duration ease-in-out;
    -webkit-transition: all $duration ease-in-out;
         -o-transition: all $duration ease-in-out;
        -ms-transition: all $duration ease-in-out;
            transition: all $duration ease-in-out;
}

@mixin transitionType($type, $duration) {
       -moz-transition: $type $duration ease-in-out;
    -webkit-transition: $type $duration ease-in-out;
         -o-transition: $type $duration ease-in-out;
        -ms-transition: $type $duration ease-in-out;
            transition: $type $duration ease-in-out;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9 only
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

@mixin scale($scale) {
  -webkit-transform: scale($scale);
      -ms-transform: scale($scale);
       -o-transform: scale($scale);
          transform: scale($scale);
}


/* placeholder */

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


@mixin backgroundFixed {
  background-image: url(../img/banner_background.jpg); 
  background-position: top center; 
  background-attachment: fixed;

  @media screen and (min-width: 1970px ) {
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
  }

   @include breakpoint(medium down) {
      background-attachment: initial; 
      -webkit-background-size: cover; 
      background-size: cover; 
   }
}


@mixin tracking( $tracking ){
  letter-spacing: ( $tracking / 1000 ) * 1em; 
}