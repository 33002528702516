// Import setup
@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";
@import "setup/mixins.scss";
@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/grid.scss"; 
@import "setup/cssowl.scss"; 
@import "setup/sprites/global.scss";
@import "setup/sprites/picto.scss";
@import "setup/sprites/share.scss";

// Import componants 
@import "componants/animate.scss";
@import "componants/dialog.scss";
@import "componants/flexslider.scss";

// Import elements
@import "elements/button.scss";
@import "elements/rte.scss";
@import "elements/messages.scss";
@import "elements/dialog.scss";
@import "elements/button.scss";
@import "elements/hamburger.scss";
@import "elements/pager.scss";
@import "elements/skip_nav.scss";
@import "elements/chosen.scss";

// Import main files
@import "global.scss";
@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "page.scss";
@import "forms.scss";
@import "print.scss";


/* BLOCKS */
@import "blocks/paragraphs.scss";
@import "blocks/page-banner.scss";
@import "blocks/login-dialog.scss";
@import "blocks/slider-recommandations.scss";


/* LISTS */
@import "lists/list-fournisseurs.scss";
@import "lists/list-faq.scss";
@import "lists/list-documents.scss";
@import "lists/list-recommandations.scss";
@import "lists/list-recherche.scss";


/* NODES */
@import "nodes/page-de-base.scss";
@import "nodes/entreprise.scss";
@import "nodes/page-fournisseurs.scss";
@import "nodes/contact_user.scss";


/* PAGES */
@import "pages/recherche.scss";
@import "pages/pages-erreur.scss";