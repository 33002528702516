ul.liste_recherche {

	li {
		margin-bottom: 30px;
		padding-bottom: 45px;
		border-bottom: 1px solid $greyBg;

		h2 {
			font-size: 16px;
			line-height: 30px;
			font-weight: 600;
			letter-spacing: 0.2px;
			color: $black;
			margin-bottom: 30px;
			@include transition(0.3s);
		}

		p {
			font-size: 14px;
			line-height: 30px;
			letter-spacing: 0.1px;
			color: $black;
		}

		a:hover h2 {
			color: $blue;
		}
	}
}