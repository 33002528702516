#burger {
	z-index:200;
	position: relative;
	right: 0;
	top: 33px;
	background: $black;
	height: 4px;
	width: 34px;
	cursor: pointer;
	@include transition(0.4s);
	float: right;

	&:before {
		@include transition(0.4s);
		content:'';
		position: absolute;
		right: 0px;
		top: -12px;
		background: $black;
		height: 4px;
		width: 34px;
	}

	&:after {
		@include transition(0.4s);
		content:'';
		position: absolute;
		right: 0px;
		top: 12px;
		background: $black;
		height: 4px;
		width: 34px;
	}

	&.opened {
		background: $red;
	   	z-index: 200;
	   	@include rotate(45deg);

	   	&:before {
	    	background: $red;
	    	@include rotate(90deg);
	    	top: 0;
	   	}

	   	&:after{
	   		opacity: 0;
	   	}
	}
}	