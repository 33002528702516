#dialogMessage{

	.dialog__content {
		background: $white;
		border-radius: 5px;
		padding: 50px;
	}
	
	#messages{	
	

		.messages{
			text-align: center;
			font-family: $ffP; 
			font-size: 20px;
			margin: 0px auto; 
			padding: 0;
			border: 0 !important;
			background: none !important;
			color: $black !important;
			letter-spacing: 1px;
			line-height: 1.6;
			font-size: 18px !important;
			font-weight: 500;

			a {
				color: $green;

				&:hover {
					color: $black;
				}
			}

			&.status{
				
				padding-top: 90px;
				background: url(../img/picto_valid.png) top center no-repeat !important;
			}

			&.error{
				
				padding-top: 90px;
				background: url(../img/picto_error.png) top center no-repeat !important;
			}

			&.warning{
				

			}
		}

		ul{
			li{
				font-size: 16px; 
				line-height: 20px; 

				&:not(:last-child){
					margin-bottom: 15px;
				}
			}
		}
	}
}
