.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.dialog {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	pointer-events: none;
	z-index: 1;
}

.dialog__overlay {
	position: absolute;
	z-index: 1;
	background: rgba(0, 0, 0, 0.7);
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;

	&:before {
		content: "";
		width: 18px;
		height: 18px;
		position: absolute;
		top: 31px;
		right: 45px;
		background: url(../img/icon_close.png) center no-repeat;
		background-size: 18px auto;
		cursor: pointer;

		@media only screen and (max-width : $screen-sm-min) {
			right: 5px;
		}

		@media only screen and (max-width : $screen-xs-min) {
			content: none;
		}
	}
}

@media only screen and (max-width : $screen-xs-min) {

	.closeDialog {
		content: "";
		width: 18px;
		height: 18px;
		position: absolute;
		top: 10px;
		right: 10px;
		background: url(../img/icon_close.png) center no-repeat;
		background-size: 18px auto;
		cursor: pointer;
	}
}

.dialog--open{
 	.dialog__overlay {
		opacity: 1;
		pointer-events: auto;
	}	
}

.dialog__content {	
	min-width: 290px;
	background: #fff;
	text-align: center;
	position: relative;
	z-index: 1000;
	opacity: 0;
}

.dialog--open .dialog__content {
	pointer-events: auto;
}

.dialog__overlay {
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.dialog.dialog--open .dialog__content,
.dialog.dialog--close .dialog__content {
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.dialog.dialog--open .dialog__content {
	-webkit-animation-name: anim-open;
	animation-name: anim-open;
	-webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
	animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.dialog.dialog--close .dialog__content {
	//-webkit-animation-name: anim-close;
	//animation-name: anim-close;
}

@-webkit-keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0, 0, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0, 0, 1); transform: scale3d(0, 0, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.5, 0.5, 1); }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.5, 0.5, 1); transform: scale3d(0.5, 0.5, 1); }
}