section.paragraphe {

	&:not(:last-child) {
		margin-bottom: 60px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}
	}

	&.image_texte {
		@include clearfix();

		figure {

			@include breakpoint(large up) {
				@include grid-column(5, 0);

				&.gauche {
					@include clearfix();
					padding-right: 40px;

					img {
						float: right;
						width: calc(((1144px / 12 * 5) - 40px) + (100vw - 1144px) / 2);
						height: auto;

						@include breakpoint(large down) {							
							width: calc(((900px / 12 * 5) - 40px) + (100vw - 900px) / 2);
						}
					}

					&+ .rte {
						padding-left: 10px;
					}
				}

				&.droite {
					float: right;
					padding-left: 40px;

					&+ .rte {
						padding-right: 10px;
					}
				}
			}

			@include breakpoint(medium down) {
				margin-bottom: 30px;

				img {
					max-width: 100%;
					height: auto;
				}
			}
		}

		.rte {

			@include breakpoint(large up) {
				@include grid-column(7, 0);
			}
		}
	}

	&.deux_colonnes {

		> ul > li {
			@include grid-column(6, $gutters);

			@include breakpoint(medium down) {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}

			figure {
				font-size: 0;
				margin-bottom: 40px;

				@include breakpoint(medium down) {
					text-align: center;
				}

				@include breakpoint(small down) {
					margin-bottom: 30px;
				}

				img {
					max-width: 100%;
					height: auto;
				}
			}

			.rte {
				text-align: center;
				padding: 0 15px;
			}
		}
	}

	&.trois_colonnes {

		> ul > li {
			@include grid-column(4, $gutters);

			@include breakpoint(medium down) {
				width: 100%;

				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
		}
	}
}