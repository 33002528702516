.dialog--open {
	z-index: 5555;
}

.dialog__content {	
	max-height: 90%;
	overflow: auto;

	@media screen and (max-width: $screen-sm) {	
		max-height: 100%;
		overflow: auto;
	}

	h2{
		
		
	}

	.content{
		
	}

	.scrollable{
		height: 100%; 
		overflow: hidden;
	}

	.dialog_bg{
		overflow: auto;
		max-height: 100%; 
	}

	.closeDialog {
		width: 18px;
		height: 18px;
		position: absolute;
		top: 30px;
		right: 30px;
		background: url(../img/icon_close.png) center no-repeat;
		background-size: 18px auto;
		cursor: pointer;
	}

}


#dialogMessage{
	.dialog__content{
		max-width: 40%; 
		.dialog_bg{
			overflow: hidden;
		}
	}
}


#dialogEtudeDownloadList{
	
	.dialog__content{
		padding: 70px 30px 30px 30px;
		width: 100%; 
		text-align: left;
		height: 100%; 
	}
}


#dialogPromo {

	.dialog_bg {
		padding: 70px;
		width: 850px;
		max-width: 100%;
		text-align: left;

		h2 {
			font-size: 30px;
			font-weight: 700;
			margin-bottom: 30px;
			line-height: 35px;
			text-transform: uppercase;

			@include breakpoint(small down) {
				font-size: 20px;
				margin-bottom: 20px;
			}
		}

		h3 {
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 30px;
			line-height: 20px;
			letter-spacing: 1px;

			@include breakpoint(small down) {
				font-size: 12px;
				margin-bottom: 20px;
			}
		}

		.rte {
			margin-bottom: 65px;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}
		}

		p.bouton {
			text-align: center;

			a {
				@include button($black, $blue);
				padding: 18px 35px;
				font-weight: 600;
			}
		}
	}
}