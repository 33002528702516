form {

	input,
	select,
	textarea {
		appearance: none;
		padding: 0;
		margin: 0;
        outline: none;
        border-radius: 0;
	}

    textarea {
        resize: none;
    }

	input[type=submit] {
		border: 0;
		box-shadow: none;
    	cursor: pointer;
	}
    
    input[type=checkbox] {
        position: absolute;
        width: 20px;
        height: 20px;
        pointer-events: none;
        opacity: 0;

        &+ label {
          	padding-left: 45px;
          	position: relative;
          	cursor: pointer;
          	line-height: 20px;
          	color: $white;
          	display: block;
          	font-size: 14px;

            a {
                color: $white;
                text-decoration: underline;
            }

          	&:before{
            	content: "";
            	width: 20px;
            	height: 20px;
            	border: solid 1px $white;
            	border-radius: 2px;
            	position: absolute;
            	top: 0;
            	left: 0;

          	}

          	&:after{
            	content: "";
            	width: 12px;
            	height: 12px;
            	background: $blue;
            	border-radius: 2px;
            	position: absolute;
            	top: 4px;
            	left: 4px;
            	opacity: 0;
            	@include transition(0.3s);
          	}
        }

        &:checked + label{

          	&:after{
            	opacity: 1;
          	}
        }
    }

    select {
        display: block;
        width: 100%;
        height: 72px;
        line-height: 70px;
        padding: 0 30px;
        padding-left: 10px;
        padding-right: 80px;
        font-size: 16px;
        color: $white;
        vertical-align: middle;
        background: url(../img/icon_chevron_white.png) right 30px top 30px no-repeat $blue;
        background-size: 17px auto;
        border: 1px solid $white;
        cursor: pointer;
    }

    .webform-component-checkboxes {
    	margin-bottom: 25px;

    	.description {
    		margin-top: 4px;
    		padding-right: 2px;
    		padding-left: 45px;
    		font-size: 12px;
    		color: $white;
    		line-height: 22px;

    		a {
    			color: $white;
    			text-decoration: underline;

    			&:hover {
    				color: $blue;
    			}
    		}
    	}
    }
}

.footer_newsletter form {

	input[type=checkbox] {

        &+ label {
          	color: $lightBlack;

          	&:before{
            	border: solid 1px $lightBlack;
            }
        }
    }

    .webform-component-checkboxes {

    	.description {
    		color: $lightBlack;

    		a {
    			color: $black;

    			&:hover {
    				color: $blue;
    			}
    		}
    	}
    }

    .webform-component-email {
    	float: left;
    	width: calc(100% - 114px);

    	input {
    		display: block;
    		width: 100%;
    		border: 1px solid $black;
    		background: $greyBg;
    		font-size: 14px;
    		font-weight: 600;
    		color: $lightBlack;
    		line-height: 45px;
            height: 47px;
    		padding-left: 28px;

	   		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  	color: $lightBlack;
			  	opacity: 1;
			  	font-weight: 400;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  	color: $lightBlack;
			  	opacity: 1;
			  	font-weight: 400;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  	color: $lightBlack;
			  	opacity: 1;
			  	font-weight: 400;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  	color: $lightBlack;
			  	opacity: 1;
			  	font-weight: 400;
			}
    	}
    }

    .form-actions {
    	float: right;
    	width: 114px;
    	font-size: 0;

    	input {
    		display: block;
    		width: 100%;
    		text-align: center;
    		line-height: 47px;
    		background: $black;
    		@include transition(0.3s);
    		color: $white;
    		font-size: 14px;
    		font-weight: 700;

    		&:hover {
    			background: $blue;
    		}
    	}
    }
}

body:not(.page-recherche) #header_recherche {

    form {

        .views-widget-filter-combine {
            float: left;
            width: calc(100% - 29px);

            label {
                display: none;
            }

            input[type=text] {
                width: 100%;
                height: 38px;
                border: 0;
                border-left: 4px solid $turquoise;
                vertical-align: middle;
                padding-left: 38px;
                font-size: 25px;
                color: $greyInput;
                font-weight: 800;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $greyInput;
                    opacity: 1;
                    font-weight: 800;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $greyInput;
                    opacity: 1;
                    font-weight: 800;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: $greyInput;
                    opacity: 1;
                    font-weight: 800;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $greyInput;
                    opacity: 1;
                    font-weight: 800;
                }
            }
        }

        .views-submit-button {
            float: right;           
            width: 29px;

            input[type=submit] {
                width: 29px;
                height: 29px;
                background: url(../img/icon_loupe_blue.png) center no-repeat;
                background-size: 100%;
                font-size: 0;
            }
        }
    }
}

body.page-recherche #header_recherche {

    form {
        @include clearfix();
        padding-right: 60px;
        position: relative;

        @include breakpoint(small down) {
            padding-right: 40px;
        }

        &:before {
            content: "Votre recherche";
            font-size: 30px;
            line-height: 50px;
            font-weight: 700;
            margin-bottom: 39px;

            @include breakpoint(small down) {
                font-size: 20px;
                line-height: 25px;
                margin-bottom: 30px;
            }
        }

        .views-widget-filter-combine {
            width: 100%;

            label {
                display: none;
            }

            input[type=text] {
                width: 100%;
                height: 60px;
                border: 1px solid $black;
                padding-left: 28px;
                font-size: 15px;
                color: $black;
                background: none;

                @include breakpoint(small down) {
                    height: 40px;
                }
            }
        }

        .views-submit-button input[type=submit] {
            position: absolute;        
            right: 0;
            bottom: 0;
            height: 60px;
            width: 60px;
            background: url(../img/icon_loupe_white_big.png) center no-repeat $black;
            background-size: 29px auto;
            font-size: 0;

            @include breakpoint(small down) {
                height: 40px;
                width: 40px;
                background-size: 20px auto;
            }

            &:hover {
                background-color: $blue;
            }
        }
    }
}

.blue_form form {
    @include row();

    > div > .form-item,
    > div > .form_item {
        margin-bottom: 35px;

        &.field_full {
            @include grid-column(12, $gutters);
        }

        &.field_half {
            @include grid-column(6, $gutters);

            @include breakpoint(small down) {
                width: 100%;
            }
        }

        &.field_password {
            margin-bottom: 0;

            > .form-item > .form-item:not(:last-child) {
                margin-bottom: 35px;
            }
        }

        input[type=text],
        input[type=password],
        input[type=email],
        textarea {
            width: 100%;
            border: 0;
            border-bottom: 1px solid $white;
            background: none;
            color: $white;
            height: 40px;
            font-size: 14px;
            letter-spacing: 0.5px;
        }

        textarea {
            height: 115px;
            margin-top: 5px;
        }

        &.webform-component-checkboxes  {

            input[type=checkbox] + label:after {
                background: $white;
            }

            .description a:hover {
                color: $greyBorder;
            }
        }
    }

    > div > .form-item > label:not(.element-invisible),
    > div > .form_item label {
        display: block;
        color: $white;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1.4px;

        .form-required {
            display: none;
        }
    }

    .captcha {
        float: left;

        @include breakpoint(large only) {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            .g-recaptcha {
                display: inline-block;
            }
        }

        @include breakpoint(small down) {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            .g-recaptcha {
                display: inline-block;
            }
        }
    }

    .form-actions {
        @include grid-column(12, $gutters);
        width: auto;
        float: right;

        input[type=submit] {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            height: 60px;
            color: $white;
            padding: 0 38px;
            background: $black;
            border: 1px solid $black;

            &:hover {
                background: $blue;
                border-color: $white;
            }
        }
    }

    .description {
        display: none;
    }
}

form#user-login,
form#user-login-form {

    label {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1.4px;

        .form-required {
            display: none;
        }
    }

    input[type=text],
    input[type=password] {
        border: 0;
        border-bottom: 1px solid $black;
        background: none;
        height: 40px;
        width: 100%;
        font-size: 14px;
    }

    .form-item {
        margin-bottom: 50px;

        &.form-item-pass {
            margin-bottom: 10px;
        }
    }
    .description {
        //display: none;
    }

    .item-list {
        text-align: right;
        margin-bottom: 20px;

        a {
            font-size: 12px;
            color: $blue;

            &:hover {
                color: $black;
            }
        }
    }

    input[type=submit] {
        @include button($black, $blue);
        padding: 18px 35px;
        font-weight: 600;
    }
}

body.page-user form#user-login {
    width: 500px;
    max-width: 100%;
    margin: 60px auto;

    @include breakpoint(small down) {
        margin: 30px auto;
    }
}

body.page-user.not-logged-in,
body.page-user-password {

    #user_right {
        float: none !important;
        margin: auto;

        form {
            position: relative !important;
        }
    }
}