.liste_faq {

	> li {
		border: 1px solid $greyBorder;
		border-radius: 5px;

		&:not(:last-child) {
			margin-bottom: 20px;

			@include breakpoint(small down) {
				margin-bottom: 10px;
			}
		}

		> article {
			@include transition(0.3s);

			> h2 {
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				padding: 16px 20px;
				padding-right: 55px;
				cursor: pointer;
				position: relative;
				@include transition(0.3s);

				@include breakpoint(small down) {
					font-size: 12px;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 20px;
					margin: auto;
					width: 15px;
					background: url(../img/icon_chevron_grey.png) right center no-repeat;
					background-size: 15px auto;
					@include transition(0.3s);
				}

				&:hover {
					color: $blue;
				}
			}

			&.active {
				background: $greyBg;

				h2 {
					color: $blue;
				}
			}
		}

		.reponse_wrap {
			height: 0;
			overflow: hidden;
			@include transitionType(height, 0.3s);

			.reponse {
				padding: 20px;

				@include breakpoint(small down) {
					padding-top: 0;
				}

				p {
					font-size: 14px;

					@include breakpoint(small down) {
						font-size: 12px;
					}
				}
			}
		}

		article.active h2:before {
			@include rotate(-180deg);
		}
	}
}