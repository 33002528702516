.liste_recommandations {

	> li {
		margin-bottom: 30px;

		figure {
			@include grid-column(2, $gutters);

			@include breakpoint(medium down) {
				width: 100%;
				text-align: center;
				margin-bottom: 30px;
			}

			img {
				max-width: 100%;
				height: auto;
				border-radius: 50%;
			}
		}

		.reco_right {
			@include grid-column(10, $gutters);

			@include breakpoint(medium down) {
				width: 100%;
			}

			.nom {
				font-size: 14px;
				letter-spacing: 14;
				text-transform: uppercase;
				margin-bottom: 30px;
				font-weight: 600;
			}

			h2 {
				font-size: 30px;
				line-height: 50px;
				font-weight: 700;
				margin-bottom: 50px;
				text-transform: uppercase;

				@include breakpoint(small down) {
					font-size: 20px;
					line-height: 30px;
					margin-bottom: 25px;
				}
			}

			.contenu {
				font-size: 16px;
				line-height: 35px;
				margin-bottom: 20px;

				@include breakpoint(large up) {
					padding-right: 100px;
				}

				@include breakpoint(small down) {
					font-size: 14px;
					line-height: 25px;
					margin-bottom: 20px;
				}
			}

			i {
				text-align: right;
				display: block;

				img {
					width: 62px;
					height: auto;
				}
			}
		}
	}
}