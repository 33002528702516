

@mixin clearfix() {
  
  &:before, &:after {
    content: " "; 
    display: table; 
  }

  &:after {
    clear: both;
  }

}


%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}