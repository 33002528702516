$grid-row-width: 1144px;
$columns: 12;
$gutters: 20px;
$pad: $gutters / 2;
$padMinus : -($gutters / 2);

/*$breakpoints: (
  small: 0px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
*/

@mixin container(){
	@include grid-row($columns, $gutters, $grid-row-width);

	 @include breakpoint(large down) {
		max-width: 900px; 
	 }	

	  @include breakpoint(medium down) {
			max-width: 600px; 
	  }

	   @include breakpoint(small down) {
			max-width: 100%; 
			padding: 0px 10px; 
	  }
}
.container {
  @include container();
}

@mixin row($padding:$padMinus){
	@include clearfix; 
	margin: 0px $padding;		
}

.row {
	@include row();
}

.clear {
	clear: both;
}